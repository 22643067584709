/* Box sizing rules */
/* Remove default margin and padding */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html,
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove text decoration and set inherit color */
a {
  text-decoration: none;
  color: inherit;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
*::before,
*::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* Colors */
/* Font Family */
/* Breakpoints */
.carousel {
  --item-count: 1;
  --track-index: 0;
  --button-width: 0.6rem;
  --gap: 1rem;
  --carousel-track-total-gap: calc(var(--gap) * (var(--item-count) - 1));
  --carousel-visible-track-width: calc(100% - var(--carousel-track-total-gap));
  --carousel-item-width: calc(var(--carousel-visible-track-width) / var(--item-count));
  display: flex;
  justify-content: center;
  overflow: hidden;
}
@media (min-width: 40rem) {
  .carousel {
    --item-count: 2;
  }
}
@media (min-width: 64rem) {
  .carousel {
    --item-count: 3;
    --button-width: 1rem;
  }
}
@media (min-width: 90rem) {
  .carousel {
    --item-count: 4;
  }
}
.carousel__button {
  cursor: pointer;
  flex-shrink: 0;
  border: 0;
  background-color: white;
  width: var(--button-width);
  z-index: 10;
}
.carousel__track {
  display: flex;
  flex-grow: 1;
  grid-gap: var(--gap);
  transform: translateX(calc(var(--track-index) * (-100% - var(--gap))));
  transition: transform 400ms ease-in-out;
  margin: 0 var(--gap);
}
.carousel__item {
  flex: 0 0 var(--carousel-item-width);
  max-width: var(--carousel-item-width);
  border-radius: 5px;
  overflow: hidden;
}
.carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0;
  font-family: "Bellefair", serif;
  padding-block: 2rem;
}
.header__logo {
  width: 10rem;
}
.header__nav-toggler {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  cursor: pointer;
  z-index: 150;
}
@media (min-width: 40rem) {
  .header__nav-toggler {
    display: none;
  }
}
.header__nav-toggler span {
  width: 24px;
  height: 3px;
  border-radius: 2px;
  background: #221060;
  transition: all 150ms ease-in-out;
}
.header__nav-toggler.open {
  position: fixed;
  inset: 2.5rem 1rem auto auto;
}
.header__nav-toggler.open span:nth-of-type(1) {
  transform: translateY(7px) rotate(45deg);
}
.header__nav-toggler.open span:nth-of-type(2) {
  opacity: 0;
}
.header__nav-toggler.open span:nth-of-type(3) {
  transform: translateY(-7px) rotate(-45deg);
}
.header__socials {
  display: none;
}
.header__socials a {
  width: 2.5rem;
  height: 2.5rem;
}
.header__socials a svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 64rem) {
  .header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
  }
}

.nav {
  visibility: hidden;
  transform: translateX(100%);
  position: fixed;
  inset: 0 0 0 auto;
  padding-top: 7rem;
  width: 14rem;
  background: white;
  height: 100%;
  z-index: 100;
  transition: all 300ms ease-in-out;
}
@media (min-width: 40rem) {
  .nav {
    padding-top: unset;
    width: unset;
    position: static;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transition: unset;
  }
}
.nav.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  box-shadow: 0 0 999px 10000px rgba(0, 0, 0, 0.4);
}
.nav__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 1rem;
}
@media (min-width: 40rem) {
  .nav__list {
    flex-direction: row;
  }
}
.nav__link {
  cursor: pointer;
  width: 100%;
  padding-left: 1rem;
  padding-block: 0.5rem;
}
.nav__link:hover, .nav__link:active {
  color: #26126d;
}
.nav__item {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

.container {
  max-width: 90rem;
  margin-inline: auto;
  padding-inline: 1rem;
}
@media (min-width: 40rem) {
  .container {
    padding-inline: 2rem;
  }
}
@media (min-width: 64rem) {
  .container {
    padding-inline: 5rem;
  }
}

:root {
  scroll-behavior: smooth;
  /* Font size */
  --size-100: 1rem;
  --size-200: 1.5rem;
  --size-300: 1.75rem;
  --size-400: 2rem;
  --size-500: 2.25rem;
  --size-600: 2.75rem;
  --size-800: 3.5rem;
}
@media (min-width: 40rem) {
  :root {
    --size-100: 1.25rem;
    --size-200: 1.75rem;
    --size-300: 2.25rem;
    --size-400: 3rem;
    --size-500: 3.75rem;
    --size-600: 4.5rem;
    --size-800: 7rem;
  }
}
@media (min-width: 64rem) {
  :root {
    --size-400: 4rem;
    --size-500: 4.5rem;
    --size-600: 6.5rem;
    --size-800: 9rem;
  }
}
@media (min-width: 90rem) {
  :root {
    --size-400: 5rem;
    --size-500: 5.25rem;
    --size-600: 8rem;
    --size-800: 10rem;
  }
}

html,
body {
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.hero {
  text-transform: uppercase;
  padding-block: 2.5rem;
  margin-bottom: 2.5rem;
}
.hero__heading {
  font-family: "Bellefair", serif;
  font-size: var(--size-800);
  font-weight: 400;
  color: #221060;
  letter-spacing: 0.05em;
  line-height: 1;
}
.hero__subHeading {
  font-family: "Barlow Condensed", sans-serif;
  font-size: var(--size-400);
  font-weight: 200;
  color: #171717;
  letter-spacing: 0.15em;
  line-height: 1;
  margin-bottom: 2rem;
}
.hero__image-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0;
  position: relative;
  margin-bottom: 4rem;
}
.hero__image-section img:nth-of-type(1), .hero__image-section img:nth-of-type(2) {
  display: none;
}
@media (min-width: 64rem) {
  .hero__image-section img:nth-of-type(1), .hero__image-section img:nth-of-type(2) {
    display: block;
  }
}
.hero__image-section img:nth-of-type(1) {
  width: 20%;
}
.hero__image-section img:nth-of-type(2) {
  width: 22.5%;
}
.hero__image-section img:nth-of-type(3) {
  width: min(75%, 20rem);
}
@media (min-width: 64rem) {
  .hero__image-section img:nth-of-type(3) {
    width: 32.5%;
  }
}
.hero__image-section img:nth-of-type(4) {
  position: absolute;
  top: 40%;
  left: 45%;
  width: min(50%, 15rem);
}
@media (min-width: 64rem) {
  .hero__image-section img:nth-of-type(4) {
    position: static;
    width: 25%;
  }
}

.about {
  background-color: rgba(27, 6, 96, 0.05);
  padding-block: 5rem;
}
.about__inner {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
}
@media (min-width: 64rem) {
  .about__inner {
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 6.25rem;
  }
}
.about__heading, .location__heading, .collection__heading, .about__right [class$=__heading] {
  font-family: "Bellefair", serif;
  font-size: var(--size-600);
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #221060;
  margin-bottom: 0.5em;
  text-align: center;
  max-width: 20ch;
  margin-inline: auto;
}
@media (min-width: 64rem) {
  .about__heading, .location__heading, .collection__heading, .about__right [class$=__heading] {
    text-align: left;
    margin-inline: unset;
  }
}
.about__desc, .about__right [class$=__desc] {
  font-family: Nunito, sans-serif;
  font-size: var(--size-100);
  font-weight: 500;
  line-height: 175%;
  color: #171717;
  text-align: center;
  max-width: 40ch;
  margin-inline: auto;
}
@media (min-width: 64rem) {
  .about__desc, .about__right [class$=__desc] {
    margin-inline: unset;
    text-align: left;
  }
}
.about__right {
  display: grid;
  grid-gap: 3rem;
}
@media (min-width: 64rem) {
  .about__right {
    grid-gap: 5rem;
  }
}
.about__right [class$=__heading] {
  font-size: var(--size-300);
}
.about__right [class$=__desc] {
  font-size: var(--size-100);
}

.tagline {
  background-color: #221060;
  padding-block: 2rem;
}
@media (min-width: 64rem) {
  .tagline {
    padding-block: 3rem;
  }
}
.tagline__content {
  font-size: var(--size-100);
  line-height: 175%;
  color: #fff;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.tagline__content p {
  text-align: center;
  max-width: 70ch;
}
@media (min-width: 64rem) {
  .tagline__content p {
    text-align: left;
  }
}

.collection {
  padding-block: 5rem;
}
.collection__section-heading {
  font-size: var(--size-500);
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  margin-bottom: 4rem;
  line-height: 1;
  text-align: center;
}
.collection__carousel {
  margin-bottom: 4rem;
}

.location {
  background-color: rgba(27, 6, 94, 0.05);
  padding-block: 5rem;
}
.location__container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  padding-block: 3rem;
}
@media (min-width: 64rem) {
  .location__container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6.25rem;
  }
}
.location__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  grid-gap: 1.5rem;
}
.location iframe {
  width: min(100%, 30rem);
  aspect-ratio: 16/9;
}
.location__content {
  text-align: center;
  margin-inline: auto;
}
.location__branch {
  font-family: "Barlow Condensed", sans-serif;
  color: #221060;
  text-transform: uppercase;
  font-size: var(--size-300);
  letter-spacing: 0.05em;
  margin-bottom: 0.5rem;
}
.location__address {
  font-family: Nunito, sans-serif;
  color: #171717;
  max-width: 25ch;
  font-size: var(--size-100);
  line-height: 150%;
}

.footer {
  background-color: #221060;
  padding-block: 2.5rem;
  color: #b69ff9;
  text-align: center;
}
@media (min-width: 64rem) {
  .footer {
    text-align: left;
  }
}
@media (min-width: 64rem) {
  .footer__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    grid-gap: 0;
  }
}
.footer__section:not(:nth-last-of-type(1)) {
  margin-bottom: 2rem;
}
.footer__logo {
  width: 10rem;
  margin-bottom: 1rem;
  margin-inline: auto;
}
@media (min-width: 64rem) {
  .footer__logo {
    margin-inline: unset;
  }
}
.footer__address {
  max-width: 25ch;
  margin-inline: auto;
  margin-bottom: 1.5rem;
  font-family: Nunito, sans-serif;
  font-size: var(--size-100);
  line-height: 150%;
}
.footer__socials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
}
@media (min-width: 64rem) {
  .footer__socials {
    justify-content: flex-start;
  }
}
.footer__socials a {
  width: 2.5rem;
  height: 2.5rem;
}
.footer__socials a svg {
  width: 100%;
  height: 100%;
}
.footer__section-heading, .footer__section-links {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.footer__section-heading {
  font-family: "Barlow Condensed", sans-serif;
  font-size: var(--size-200);
  font-weight: 600;
  color: #fff;
  margin-bottom: 1rem;
}
.footer__section-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 1.5rem;
  font-family: "Bellefair", serif;
  font-size: var(--size-100);
  cursor: pointer;
}
@media (min-width: 64rem) {
  .footer__section-links {
    flex-direction: row;
  }
}

