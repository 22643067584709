@use '../abstracts'as *;

.header {
  @include flex($justify-content: space-between);
  font-family: $ff-primary;
  padding-block: 2rem;

  &__logo {
    width: 10rem;
  }

  &__nav-toggler {
    @include flex($direction: column, $gap: 4px);
    cursor: pointer;
    z-index: 150;

    @include respond-to(medium) {
      display: none;
    }

    span {
      width: 24px;
      height: 3px;
      border-radius: 2px;
      background: $primary;
      transition: all 150ms ease-in-out;
    }

    &.open {
      position: fixed;
      inset: 2.5rem 1rem auto auto;

      span:nth-of-type(1) {
        transform: translateY(7px) rotate(45deg);
      }

      span:nth-of-type(2) {
        opacity: 0;
      }

      span:nth-of-type(3) {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }

  &__socials {
    display: none;

    a {
      width: 2.5rem;
      height: 2.5rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @include respond-to(large) {
      @include flex($gap: 1rem);
    }
  }
}