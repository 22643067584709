@use '../abstracts/mixins'as *;

.container {
  max-width: 90rem;
  margin-inline: auto;
  padding-inline: 1rem;

  @include respond-to(medium) {
    padding-inline: 2rem;
  }

  @include respond-to(large) {
    padding-inline: 5rem;
  }
}