@use '../abstracts'as *;

.carousel {
  --item-count: 1;
  --track-index: 0;
  --button-width: 0.6rem;
  --gap: 1rem;

  --carousel-track-total-gap: calc(var(--gap) * (var(--item-count) - 1));
  --carousel-visible-track-width: calc(100% - var(--carousel-track-total-gap));
  --carousel-item-width: calc(var(--carousel-visible-track-width) / var(--item-count));

  @include respond-to(medium) {
    --item-count: 2;
  }

  @include respond-to(large) {
    --item-count: 3;
    --button-width: 1rem;
  }

  @include respond-to(x-large) {
    --item-count: 4;
  }

  display: flex;
  justify-content: center;
  overflow: hidden;

  &__button {
    cursor: pointer;
    flex-shrink: 0;
    border: 0;
    background-color: white;
    width: var(--button-width);
    z-index: 10;
  }

  &__track {
    display: flex;
    flex-grow: 1;
    grid-gap: var(--gap);
    transform: translateX(calc(var(--track-index) *  (-100% - var(--gap))));
    transition: transform 400ms ease-in-out;
    margin: 0 var(--gap);
  }

  &__item {
    flex: 0 0 var(--carousel-item-width);
    max-width: var(--carousel-item-width);
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}