@use '../abstracts/mixins'as *;
@use '../abstracts/variables'as *;

.nav {
  visibility: hidden;
  transform: translateX(100%);
  position: fixed;
  inset: 0 0 0 auto;
  padding-top: 7rem;
  width: 14rem;
  background: white;
  height: 100%;
  z-index: 100;
  transition: all 300ms ease-in-out;

  @include respond-to(medium) {
    padding-top: unset;
    width: unset;
    position: static;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    transition: unset;
  }

  &.open {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    box-shadow: 0 0 999px 10000px rgba($color: #000, $alpha: 0.4);
  }

  &__list {
    @include flex($direction: column, $align-items: flex-start, $gap: 1rem);

    @include respond-to(medium) {
      flex-direction: row;
    }
  }

  &__link {
    cursor: pointer;
    width: 100%;
    padding-left: 1rem;
    padding-block: 0.5rem;

    &:hover,
    &:active {
      color: $primary-dark;
    }
  }

  &__item {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
  }
}