*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html, html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a {
  color: inherit;
  text-decoration: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

.carousel {
  --item-count: 1;
  --track-index: 0;
  --button-width: .6rem;
  --gap: 1rem;
  --carousel-track-total-gap: calc(var(--gap) * (var(--item-count)  - 1) );
  --carousel-visible-track-width: calc(100% - var(--carousel-track-total-gap));
  --carousel-item-width: calc(var(--carousel-visible-track-width) / var(--item-count));
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (min-width: 40rem) {
  .carousel {
    --item-count: 2;
  }
}

@media (min-width: 64rem) {
  .carousel {
    --item-count: 3;
    --button-width: 1rem;
  }
}

@media (min-width: 90rem) {
  .carousel {
    --item-count: 4;
  }
}

.carousel__button {
  cursor: pointer;
  width: var(--button-width);
  z-index: 10;
  background-color: #fff;
  border: 0;
  flex-shrink: 0;
}

.carousel__track {
  grid-gap: var(--gap);
  transform: translateX(calc(var(--track-index) * (-100% - var(--gap)) ) );
  margin: 0 var(--gap);
  flex-grow: 1;
  transition: transform .4s ease-in-out;
  display: flex;
}

.carousel__item {
  flex: 0 0 var(--carousel-item-width);
  max-width: var(--carousel-item-width);
  border-radius: 5px;
  overflow: hidden;
}

.carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.header {
  grid-gap: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-block: 2rem;
  font-family: Bellefair, serif;
  display: flex;
}

.header__logo {
  width: 10rem;
}

.header__nav-toggler {
  grid-gap: 4px;
  cursor: pointer;
  z-index: 150;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 40rem) {
  .header__nav-toggler {
    display: none;
  }
}

.header__nav-toggler span {
  width: 24px;
  height: 3px;
  background: #221060;
  border-radius: 2px;
  transition: all .15s ease-in-out;
}

.header__nav-toggler.open {
  position: fixed;
  inset: 2.5rem 1rem auto auto;
}

.header__nav-toggler.open span:nth-of-type(1) {
  transform: translateY(7px)rotate(45deg);
}

.header__nav-toggler.open span:nth-of-type(2) {
  opacity: 0;
}

.header__nav-toggler.open span:nth-of-type(3) {
  transform: translateY(-7px)rotate(-45deg);
}

.header__socials {
  display: none;
}

.header__socials a {
  width: 2.5rem;
  height: 2.5rem;
}

.header__socials a svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 64rem) {
  .header__socials {
    grid-gap: 1rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.nav {
  visibility: hidden;
  width: 14rem;
  height: 100%;
  z-index: 100;
  background: #fff;
  padding-top: 7rem;
  transition: all .3s ease-in-out;
  position: fixed;
  inset: 0 0 0 auto;
  transform: translateX(100%);
}

@media (min-width: 40rem) {
  .nav {
    padding-top: unset;
    width: unset;
    visibility: visible;
    opacity: 1;
    transition: unset;
    position: static;
    transform: translateX(0);
  }
}

.nav.open {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  box-shadow: 0 0 999px 10000px #0006;
}

.nav__list {
  grid-gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 40rem) {
  .nav__list {
    flex-direction: row;
  }
}

.nav__link {
  cursor: pointer;
  width: 100%;
  padding-left: 1rem;
  padding-block: .5rem;
}

.nav__link:hover, .nav__link:active {
  color: #26126d;
}

.nav__item {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
}

.container {
  max-width: 90rem;
  margin-inline: auto;
  padding-inline: 1rem;
}

@media (min-width: 40rem) {
  .container {
    padding-inline: 2rem;
  }
}

@media (min-width: 64rem) {
  .container {
    padding-inline: 5rem;
  }
}

:root {
  scroll-behavior: smooth;
  --size-100: 1rem;
  --size-200: 1.5rem;
  --size-300: 1.75rem;
  --size-400: 2rem;
  --size-500: 2.25rem;
  --size-600: 2.75rem;
  --size-800: 3.5rem;
}

@media (min-width: 40rem) {
  :root {
    --size-100: 1.25rem;
    --size-200: 1.75rem;
    --size-300: 2.25rem;
    --size-400: 3rem;
    --size-500: 3.75rem;
    --size-600: 4.5rem;
    --size-800: 7rem;
  }
}

@media (min-width: 64rem) {
  :root {
    --size-400: 4rem;
    --size-500: 4.5rem;
    --size-600: 6.5rem;
    --size-800: 9rem;
  }
}

@media (min-width: 90rem) {
  :root {
    --size-400: 5rem;
    --size-500: 5.25rem;
    --size-600: 8rem;
    --size-800: 10rem;
  }
}

html, body {
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.hero {
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  padding-block: 2.5rem;
}

.hero__heading {
  font-family: Bellefair, serif;
  font-size: var(--size-800);
  color: #221060;
  letter-spacing: .05em;
  font-weight: 400;
  line-height: 1;
}

.hero__subHeading {
  font-family: Barlow Condensed, sans-serif;
  font-size: var(--size-400);
  color: #171717;
  letter-spacing: .15em;
  margin-bottom: 2rem;
  font-weight: 200;
  line-height: 1;
}

.hero__image-section {
  grid-gap: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  display: flex;
  position: relative;
}

.hero__image-section img:nth-of-type(1), .hero__image-section img:nth-of-type(2) {
  display: none;
}

@media (min-width: 64rem) {
  .hero__image-section img:nth-of-type(1), .hero__image-section img:nth-of-type(2) {
    display: block;
  }
}

.hero__image-section img:nth-of-type(1) {
  width: 20%;
}

.hero__image-section img:nth-of-type(2) {
  width: 22.5%;
}

.hero__image-section img:nth-of-type(3) {
  width: min(75%, 20rem);
}

@media (min-width: 64rem) {
  .hero__image-section img:nth-of-type(3) {
    width: 32.5%;
  }
}

.hero__image-section img:nth-of-type(4) {
  width: min(50%, 15rem);
  position: absolute;
  top: 40%;
  left: 45%;
}

@media (min-width: 64rem) {
  .hero__image-section img:nth-of-type(4) {
    width: 25%;
    position: static;
  }
}

.about {
  background-color: #1b06600d;
  padding-block: 5rem;
}

.about__inner {
  grid-gap: 3rem;
  grid-template-columns: 1fr;
  display: grid;
}

@media (min-width: 64rem) {
  .about__inner {
    grid-gap: 6.25rem;
    grid-template-columns: 1.5fr 1fr;
  }
}

.about__heading, .location__heading, .collection__heading, .about__right [class$="__heading"] {
  font-family: Bellefair, serif;
  font-size: var(--size-600);
  text-transform: uppercase;
  letter-spacing: .05em;
  color: #221060;
  text-align: center;
  max-width: 20ch;
  margin-bottom: .5em;
  margin-inline: auto;
  font-weight: 400;
  line-height: 1;
}

@media (min-width: 64rem) {
  .about__heading, .location__heading, .collection__heading, .about__right [class$="__heading"] {
    text-align: left;
    margin-inline: unset;
  }
}

.about__desc, .about__right [class$="__desc"] {
  font-family: Nunito, sans-serif;
  font-size: var(--size-100);
  color: #171717;
  text-align: center;
  max-width: 40ch;
  margin-inline: auto;
  font-weight: 500;
  line-height: 175%;
}

@media (min-width: 64rem) {
  .about__desc, .about__right [class$="__desc"] {
    margin-inline: unset;
    text-align: left;
  }
}

.about__right {
  grid-gap: 3rem;
  display: grid;
}

@media (min-width: 64rem) {
  .about__right {
    grid-gap: 5rem;
  }
}

.about__right [class$="__heading"] {
  font-size: var(--size-300);
}

.about__right [class$="__desc"] {
  font-size: var(--size-100);
}

.tagline {
  background-color: #221060;
  padding-block: 2rem;
}

@media (min-width: 64rem) {
  .tagline {
    padding-block: 3rem;
  }
}

.tagline__content {
  font-size: var(--size-100);
  color: #fff;
  letter-spacing: .15em;
  text-transform: uppercase;
  line-height: 175%;
}

.tagline__content p {
  text-align: center;
  max-width: 70ch;
}

@media (min-width: 64rem) {
  .tagline__content p {
    text-align: left;
  }
}

.collection {
  padding-block: 5rem;
}

.collection__section-heading {
  font-size: var(--size-500);
  text-transform: uppercase;
  letter-spacing: .15em;
  text-align: center;
  margin-bottom: 4rem;
  font-family: Barlow Condensed, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.collection__carousel {
  margin-bottom: 4rem;
}

.location {
  background-color: #1b065e0d;
  padding-block: 5rem;
}

.location__container {
  grid-gap: 4rem;
  grid-template-columns: 1fr;
  padding-block: 3rem;
  display: grid;
}

@media (min-width: 64rem) {
  .location__container {
    grid-gap: 6.25rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.location__section {
  grid-gap: 1.5rem;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.location iframe {
  width: min(100%, 30rem);
  aspect-ratio: 16 / 9;
}

.location__content {
  text-align: center;
  margin-inline: auto;
}

.location__branch {
  color: #221060;
  text-transform: uppercase;
  font-family: Barlow Condensed, sans-serif;
  font-size: var(--size-300);
  letter-spacing: .05em;
  margin-bottom: .5rem;
}

.location__address {
  color: #171717;
  max-width: 25ch;
  font-family: Nunito, sans-serif;
  font-size: var(--size-100);
  line-height: 150%;
}

.footer {
  color: #b69ff9;
  text-align: center;
  background-color: #221060;
  padding-block: 2.5rem;
}

@media (min-width: 64rem) {
  .footer {
    text-align: left;
  }

  .footer__inner {
    grid-gap: 0;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    display: flex;
  }
}

.footer__section:not(:nth-last-of-type(1)) {
  margin-bottom: 2rem;
}

.footer__logo {
  width: 10rem;
  margin-bottom: 1rem;
  margin-inline: auto;
}

@media (min-width: 64rem) {
  .footer__logo {
    margin-inline: unset;
  }
}

.footer__address {
  max-width: 25ch;
  margin-inline: auto;
  font-family: Nunito, sans-serif;
  font-size: var(--size-100);
  margin-bottom: 1.5rem;
  line-height: 150%;
}

.footer__socials {
  grid-gap: 1.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 64rem) {
  .footer__socials {
    justify-content: flex-start;
  }
}

.footer__socials a {
  width: 2.5rem;
  height: 2.5rem;
}

.footer__socials a svg {
  width: 100%;
  height: 100%;
}

.footer__section-heading, .footer__section-links {
  text-transform: uppercase;
  letter-spacing: .05em;
}

.footer__section-heading {
  font-family: Barlow Condensed, sans-serif;
  font-size: var(--size-200);
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 600;
}

.footer__section-links {
  grid-gap: 1.5rem;
  font-family: Bellefair, serif;
  font-size: var(--size-100);
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 64rem) {
  .footer__section-links {
    flex-direction: row;
  }
}

/*# sourceMappingURL=index.1b9117ac.css.map */
