@use './variables'as *;

// Mixin for flex containers
@mixin flex ($direction: row,
  $align-items: center,
  $justify-content: center,
  $gap: 0) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
  grid-gap: $gap;
}

// Mixin for absolute positioning
@mixin absolute ($top: auto, $right: auto, $bottom: auto, $left: auto) {
  position: absolute;
  inset: $top $right $bottom $left;
}

// Mixin for element sizing
@mixin size ($width: 100%, $height: $width) {
  width: $width;
  height: $height;
}

// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {

  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {

    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}