/* Colors */
$primary : hsl(253, 71%, 22%);
$primary-light: hsl(253, 71%, 40%);
$primary-dark : hsl(253, 71%, 25%);
$bg-dark: hsl(0, 0%, 15%);
$text-primary: hsl(0, 0%, 9%);

/* Font Family */
$ff-primary: 'Bellefair', serif;
$ff-secondary: 'Barlow Condensed', sans-serif;
$ff-nunito: Nunito, sans-serif;

/* Breakpoints */
$breakpoints: (
  'medium': 40rem,
  'large': 64rem,
  'x-large': 90rem
);
