@use "../abstracts" as *;

:root {
  scroll-behavior: smooth;

  /* Font size */
  --size-100: 1rem;
  --size-200: 1.5rem;
  --size-300: 1.75rem;
  --size-400: 2rem;
  --size-500: 2.25rem;
  --size-600: 2.75rem;
  --size-800: 3.5rem;

  @include respond-to(medium) {
    --size-100: 1.25rem;
    --size-200: 1.75rem;
    --size-300: 2.25rem;
    --size-400: 3rem;
    --size-500: 3.75rem;
    --size-600: 4.5rem;
    --size-800: 7rem;
  }

  @include respond-to(large) {
    --size-400: 4rem;
    --size-500: 4.5rem;
    --size-600: 6.5rem;
    --size-800: 9rem;
  }

  @include respond-to(x-large) {
    --size-400: 5rem;
    --size-500: 5.25rem;
    --size-600: 8rem;
    --size-800: 10rem;
  }
}

html,
body {
  overflow-x: hidden;
}

.no-scroll {
  overflow: hidden;
}

.hero {
  text-transform: uppercase;
  padding-block: 2.5rem;
  margin-bottom: 2.5rem;

  &__heading {
    font-family: $ff-primary;
    font-size: var(--size-800);
    font-weight: 400;
    color: $primary;
    letter-spacing: 0.05em;
    line-height: 1;
  }

  &__subHeading {
    font-family: $ff-secondary;
    font-size: var(--size-400);
    font-weight: 200;
    color: $text-primary;
    letter-spacing: 0.15em;
    line-height: 1;
    margin-bottom: 2rem;
  }

  &__image-section {
    @include flex($direction: row);
    position: relative;
    margin-bottom: 4rem;

    img {

      &:nth-of-type(1),
      &:nth-of-type(2) {
        display: none;

        @include respond-to(large) {
          display: block;
        }
      }

      &:nth-of-type(1) {
        width: 20%;
      }

      &:nth-of-type(2) {
        width: 22.5%;
      }

      &:nth-of-type(3) {
        width: min(75%, 20rem);

        @include respond-to(large) {
          width: 32.5%;
        }
      }

      &:nth-of-type(4) {
        position: absolute;
        top: 40%;
        left: 45%;
        width: min(50%, 15rem);

        @include respond-to(large) {
          position: static;
          width: 25%;
        }
      }
    }
  }
}

.about {
  background-color: hsla(254, 88%, 20%, 0.05);
  padding-block: 5rem;

  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 3rem;

    @include respond-to(large) {
      grid-template-columns:1.5fr 1fr;
      grid-gap: 6.25rem;
    }
  }

  &__heading {
    font-family: $ff-primary;
    font-size: var(--size-600);
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: $primary;
    margin-bottom: 0.5em;
    text-align: center;
    max-width: 20ch;
    margin-inline: auto;

    @include respond-to(large) {
      text-align: left;
      margin-inline: unset;
    }
  }

  &__desc {
    font-family: $ff-nunito;
    font-size: var(--size-100);
    font-weight: 500;
    line-height: 175%;
    color: $text-primary;
    text-align: center;
    max-width: 40ch;
    margin-inline: auto;

    @include respond-to(large) {
      margin-inline: unset;
      text-align: left;
    }
  }

  &__right {
    display: grid;
    grid-gap: 3rem;

    @include respond-to(large) {
      grid-gap: 5rem;
    }

    [class$="__heading"] {
      @extend .about__heading;
      font-size: var(--size-300);
    }

    [class$="__desc"] {
      @extend .about__desc;
      font-size: var(--size-100);
    }
  }
}

.tagline {
  background-color: $primary;
  padding-block: 2rem;

  @include respond-to(large) {
    padding-block: 3rem;
  }

  &__content {
    font-size: var(--size-100);
    line-height: 175%;
    color: #fff;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    p {
      text-align: center;
      max-width: 70ch;

      @include respond-to(large) {
        text-align: left;
      }
    }
  }
}

.collection {
  padding-block: 5rem;

  &__heading {
    @extend .about__heading;
  }

  &__section {
    &-heading {
      font-size: var(--size-500);
      font-family: $ff-secondary;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      margin-bottom: 4rem;
      line-height: 1;
      text-align: center;
    }
  }

  &__carousel {
    margin-bottom: 4rem;
  }
}

.location {
  background-color: rgba(27, 6, 94, 0.05);
  padding-block: 5rem;

  &__heading {
    @extend .about__heading;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    padding-block: 3rem;

    @include respond-to(large) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 6.25rem;
    }
  }

  &__section {
    @include flex($direction: column,
      $gap: 1.5rem,
      $align-items: center,
      $justify-content: space-around);
  }

  iframe {
    width: min(100%, 30rem);
    aspect-ratio: 16 / 9;
  }

  &__content {
    text-align: center;
    margin-inline: auto;
  }

  &__branch {
    font-family: $ff-secondary;
    color: $primary;
    text-transform: uppercase;
    font-size: var(--size-300);
    letter-spacing: 0.05em;
    margin-bottom: 0.5rem;
  }

  &__address {
    font-family: $ff-nunito;
    color: $text-primary;
    max-width: 25ch;
    font-size: var(--size-100);
    line-height: 150%;
  }
}

.footer {
  background-color: $primary;
  padding-block: 2.5rem;
  color: hsl(255, 88%, 80%);
  text-align: center;

  @include respond-to(large) {
    text-align: left;
  }

  &__inner {
    @include respond-to(large) {
      @include flex($justify-content: space-around, $align-items: flex-start);
    }
  }

  &__section:not(:nth-last-of-type(1)) {
    margin-bottom: 2rem;
  }

  &__logo {
    width: 10rem;
    margin-bottom: 1rem;
    margin-inline: auto;

    @include respond-to(large) {
      margin-inline: unset;
    }
  }

  &__address {
    max-width: 25ch;
    margin-inline: auto;
    margin-bottom: 1.5rem;
    font-family: $ff-nunito;
    font-size: var(--size-100);
    line-height: 150%;
  }

  &__socials {
    @include flex($gap: 1.5rem);

    @include respond-to(large) {
      justify-content: flex-start;
    }

    a {
      width: 2.5rem;
      height: 2.5rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__section {

    &-heading,
    &-links {
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    &-heading {
      font-family: $ff-secondary;
      font-size: var(--size-200);
      font-weight: 600;
      color: #fff;
      margin-bottom: 1rem;
    }

    &-links {
      @include flex($direction: column, $gap: 1.5rem);
      font-family: $ff-primary;
      font-size: var(--size-100);
      cursor: pointer;

      @include respond-to(large) {
        flex-direction: row;
      }
    }
  }
}